<template>
  <div class="recruit-list">
    <template v-if="list && list.length">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item"
        @click="handleGo(item)"
      >
        <div class="left">
          <div class="day">{{ item.ReleaseTime | dateTimeFilter("dd") }}</div>
          <div class="month">
            {{ item.ReleaseTime | dateTimeFilter("yyyy-MM") }}
          </div>
        </div>
        <div class="right">
          <div class="headline">{{ item.Headline }}</div>
          <div class="text-line-1">{{ item.Summary }}</div>
        </div>
      </div>
    </template>
    <div v-else class="g-nodata"></div>
  </div>
</template>

<script>
import { dateTimeFilter } from "@/filters/index";
export default {
  props: {
    list: {
      type: Array
    }
  },
  filters: {
    dateTimeFilter
  },
  methods: {
    handleGo(item) {
      this.$router.push(item.Link);
    }
  }
};
</script>

<style lang="scss" scoped>
.recruit-list {
  width: 100%;
  .item {
    padding: 16px 0;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    .left {
      padding: 12px;
      background: #f0f0f0;
      border-radius: 4px;
      .day {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #598DF3;
        line-height: 32px;
      }
      .month {
        margin-top: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 10px;
      }
    }
    .right {
      flex: 1;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      .headline {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
      }
    }
    &:hover {
      cursor: pointer;
      .left {
        background-color: #598DF3;
        .day,
        .month {
          color: #fff;
        }
      }
      .headline {
        color: #598DF3;
      }
    }
  }
}
</style>
