<template>
  <div class="g-wrap">
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" @click="handleGo(item)">
        <el-image style="width: 72px; height: 72px;border-radius:4px;" :src="item.LogoFullPath||'https://zxj.ve-city.com/files/zxj/portal/default_logo.png'">
        </el-image>
        <div class="right">
          <div class="headline">{{ item.EnterpriseName }}</div>
          <div class="intro">
            <!-- <span>
              <span>{{ item.Type }}</span>
              <span>/</span>
              <span>{{`${item.Tag}`  }}</span>
            </span> -->
            <span  v-if="item.RecuritJobCount>0">
              <span>在招职位：</span> 
              <span class="post" v-for="(post, i) in item.RecuritJobList.slice(0, 4)" :key="i">{{ post.Name }}</span>
              <span class="post" v-if="item.RecuritJobCount > 4">等{{ item.RecuritJobCount }}个职位</span>
            </span>
          </div>
        </div>
      </div>
      <div class="loading">
        <div v-if="noMore" class="color-light">—— 没有更多了 ——</div>
        <el-button v-else @click="load" plain type="primary" :loading="loading">{{loading?'加载中':'加载更多'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnterprisePage } from "@/api/schoolenterprise";
export default {
  data() {
    return {
      loading: false,
      list: [ ],
      pageIndex:0
    };
  },
  computed: {
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    }
  },
  created() {
    this.pageIndex = 0
    this.load()
  },
  methods: { 
    load() {
      this.loading=true
      this.pageIndex++;
      var query = {
        PageSize: 5,
        PageIndex: this.pageIndex,
        KeyWord: this.KeyWord,
        SchoolId: this.$route.query.id
      };
      getEnterprisePage(query).then(res => { 
        if (res.IsSuccess) {
          this.list = this.list.concat(res.Result.Data);
          this.totalCount = res.Result.TotalCount;
          this.loading = false;
          console.log(this.totalCount, this.list.length)

        }
      }); 
    },
    handleGo(item) {
      // this.$router.push(`/cooperation/home-enterprise/info?id=${item.Id}`);
    }
  }  
};
</script>

<style lang="scss" scoped>
.g-wrap {
  .list {
    margin-top: 24px;
    width: 100%;
    .item {
      display: flex;
      padding: 16px 0;
      border-bottom: 1px solid #eeeeee;
      box-sizing: border-box;
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid rgba(#598DF3, 0.1);
        .headline {
          color: #598DF3;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 20px;
      .headline {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
      }
      .intro {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        .post {
          padding: 2px 8px;
          margin-left: 16px;
          background: #f7f8f9;
          border-radius: 2px;
        }
      }
    }
    .loading {
      margin: 24px 0;
      text-align: center;
    }
  }
}
</style>
