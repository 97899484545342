<template>
  <div class="g-wrap">
    <div class="list">
      <Recruit :list="list" />
      <div class="loading">
        <div v-if="noMore" class="color-light">—— 没有更多了 ——</div>
        <el-button v-else @click="load" plain type="primary" :loading="loading">{{loading?'加载中':'加载更多'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageList } from "@/api/enrollmentnews";
import Recruit from "@/components/Recruit";

export default {
  components: {
    Recruit
  },
  data() {
    return {
      loading: false,
      queryData: {
        KeyWord: "",
        PageSize: 10,
        PageIndex: 0,
        SchoolId:this.$route.query.id
      },
      totalCount: 0,
      list: []
    };
  },
  computed: {
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    }
  },
  mounted(){
    this.load()
  },
  methods: {
    load() {
      this.loading = true;
      this.queryData.PageIndex++;
      getPageList(this.queryData).then(({ Result: { Data, TotalCount } }) => {
        this.list = this.useLink(this.list.concat(Data));
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    useLink(list) {
      return list.map(el => {
        return {
          ...el,
          Link: `/recruitment/enrollment-detail?id=${el.Id}`
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.g-wrap {
  .list {
    margin-top: 24px;
    width: 100%;
    .loading {
      margin: 24px 0;
      text-align: center;
    }
  }
}
</style>
