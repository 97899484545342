import { CooperationTypeEnum } from '@/utils/constant';
import request from '@/utils/request'
const controller = '/SchoolEnterprise'

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: 'get',
    params
  });

const getEnterprisePage = params =>
  request({
    url: `${controller}/GetEnterprisePage`,
    method: 'get',
    params
  });

const deleteEnterprise = id =>
  request({
    url: `${controller}/Delete`,
    method: 'post',
    params: { id }
  });


const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: 'get',
    params: { id }
  });


const insert = data =>
  request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
const edit = data =>
  request({
    url: `${controller}/Update`,
    method: "post",
    data
  });

const getCooperationList = (unitId, type) =>
  request({
    url: `${controller}/GetCooperationList`,
    method: "get",
    params: {
      unitId,
      type
    }
  });
const getCooperationEnterpriseList = unitId =>
  request({
    url: `${controller}/GetCooperationList`,
    method: "get",
    params: {
      unitId,
      type: CooperationTypeEnum.SchoolToEnterprise
    }
  });
const getCooperationSchoolList = unitId =>
  request({
    url: `${controller}/GetCooperationList`,
    method: "get",
    params: {
      unitId,
      type: CooperationTypeEnum.EnterpriseToSchool
    }
  });

export {
  getPageList,
  getEnterprisePage,
  deleteEnterprise,
  getInfo,
  insert,
  edit,
  getCooperationList,
  getCooperationEnterpriseList,
  getCooperationSchoolList
}