<template>
  <div class="comp-column-header">
    <div class="left">
      <strong>{{ data.name }}</strong>
    </div>
    <slot name="right">
      <div class="right" v-if="data.link">
        <router-link :to="data.link" :target="data.target || '_self'"
          >更多+</router-link
        >
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "column-header2",
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.comp-column-header {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 16px 0;
  .left {
    strong {
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
      &::before {
        width: 4px;
        height: 20px;
        background: #598DF3;
        border-radius: 2px;
        display: inline-block;
        content: " ";
        vertical-align: top;
        margin-top: 2px;
        margin-right: 8px;
      }
    }
  }
  .right {
    margin-top: 4px;
    a {
      color: #598DF3;
      line-height: 17px;
      &:hover {
        color: #598DF3;
      }
    }
  }
}
</style>
