<template>
  <div class="cooperation">
    <div class="banner">
      <div class="info">
        <div class="flex">
          <el-image
            style="width: 80px; height: 80px;border-radius:4px;"
            :src="schoolInfo.Logo"
          >
          </el-image>
          <div class="right">
            <div class="headline">{{ schoolInfo.SchoolName }}</div>
            <div class="intro">
              <span>{{ schoolInfo.NatureName }}</span>
              <span class="split">|</span>
              <span>{{ schoolInfo.TypeName }}</span>
              <!-- <span class="split">|</span>
              <span>50人以上</span> -->
            </div>
          </div>
        </div>
        <div class="btn-contactus" @click="showContactus = true">
          <i class="iconfont iconlianxiqiyeyuanxiao mr-4"></i>
          <span>联系我们</span>
        </div>
        <el-dialog
          title="校企合作联系方式"
          :visible.sync="showContactus"
          width="30%"
        >
          <div class="contactus-list">
            <div class="contactus-item">
              <div class="contactus-icon-wrap">
                <i class="icon-style iconfont iconlianxidianhua"></i>
              </div>
              <span class="contactus-text">{{
                `${schoolInfo.CooperationPhone ||
                  "--"}（${schoolInfo.CooperationMan || "--"}）`
              }}</span>
            </div>
            <div class="contactus-item">
              <div class="contactus-icon-wrap">
                <i class="icon-style iconfont iconwechat"></i>
              </div>
              <span class="contactus-text">{{
                schoolInfo.CooperationWechat || "--"
              }}</span>
            </div>
            <div class="contactus-item">
              <div class="contactus-icon-wrap">
                <i class="icon-style iconfont iconlianxidizhi"></i>
              </div>
              <span class="contactus-text">{{ schoolInfo.Address }}</span>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="g-wrap">
      <div class="g-tabs">
        <router-link
          v-for="item in tabList"
          :key="item.id"
          :to="{
            path: `/cooperation/home-school/` + item.id,
            query: $route.query
          }"
          :class="{ active: item.id == activeTab }"
        >
          {{ item.text }}
        </router-link>
      </div>
    </div>
    <component :is="activeComponent" :school-info="schoolInfo"></component>
  </div>
</template>

<script>
import info from "./components/School/Info";
import enrollment from "./components/School/Enrollment";
import partner from "./components/School/Partner";
import { getInfo } from "@/api/schoolInfo";
export default {
  components: {
    info,
    enrollment,
    partner
  },
  data() {
    return {
      tabList: [
        {
          id: "info",
          component: info,
          text: "院校简介"
        },
        {
          id: "enrollment",
          component: enrollment,
          text: "招生简章"
        },
        {
          id: "partner",
          component: partner,
          text: "合作企业"
        }
      ],
      activeTab: "show",
      activeComponent: info,
      schoolInfo: {},
      showContactus: false
    };
  },
  watch: {
    $route() {
      this.init();
    }
  },
  methods: {
    init() {
      const path = this.$route.path.split("/");
      const id = path[path.length - 1];
      const matched = this.tabList.filter(item => item.id === id);
      if (matched && matched.length) {
        this.activeTab = matched[0].id;
        this.activeComponent = matched[0].component;
      }
      getInfo(this.$route.query.id).then(res => {
        this.schoolInfo = res.Result;
      });
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.cooperation {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  .banner {
    background: url("~@/assets/images/banner/school.png") no-repeat center top;
    background-size: 100% 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    .info {
      width: 1100px;
      height: calc(100% - 48px);
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: space-between;
      .right {
        margin-left: 16px;
        .headline {
          margin-top: 8px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 33px;
        }
        .intro {
          margin-top: 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          .split {
            line-height: 1;
            margin: 0 4px;
          }
        }
      }
      .btn-contactus {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #ffffff;
        width: 116px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #ffffff;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .g-wrap {
    position: relative;
    margin: 0 auto;
  }
  .contactus-list {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 20px 20px 20px;

    .contactus-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .contactus-icon-wrap {
        width: 36px;
        height: 36px;
        background: #eeeeee;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;

        .icon-style {
          color: #333333;
          font-size: 16px;
        }
      }
      .contactus-text {
        color: #333333;
        font-size: 16px;
      }
    }
  }
}
</style>
